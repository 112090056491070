import ReactDOM from "react-dom";
import "translations/i18n";

import AppSetup from "App/AppSetup";
import App from "App";

ReactDOM.render(
  <AppSetup>
    <App />
  </AppSetup>,
  document.getElementById("root")
);
